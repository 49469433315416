@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@300;400&family=Noto+Sans+KR:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gugi&display=swap');

*{
    font-family: 'IBM Plex Sans KR', sans-serif;
    
}

body {
    background-color: #1D3557;
    padding-top: 65px;
    margin: 0;
}

::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
}

span{
    font-family: 'Gugi', sans-serif;
}

.ov::-webkit-scrollbar {
    display: none; 
}
